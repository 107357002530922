vue
<template>
  <button class="qi-button" @click="handleClick" :disabled="isLoading">
    <span v-if="isLoading" class="loader"></span>
    <span v-else><slot>保存</slot></span>
  </button>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false // 用于控制 loading 状态
    };
  },
  methods: {
    handleClick() {
      this.isLoading = true; // 设置为 loading 状态
      // 模拟异步操作，例如 API 请求
      setTimeout(() => {
        this.isLoading = false; // 操作完成后恢复状态
        this.$emit('click'); // 触发 click 事件
      }, 2000); // 2秒后结束 loading
    }
  }
}
</script>

<style scoped>
.qi-button {
  width: 71px; /* 默认宽度 */
  height: 36px; /* 默认高度 */
  background-color: #0fa47f; /* 主体颜色 */
  color: white; /* 文字颜色 */
  border: none; /* 无边框 */
  border-radius: 5px; /* 圆角 */
  cursor: pointer; /* 鼠标指针 */
  position: relative; /* 使 loader 可以绝对定位 */
}

.qi-button:disabled {
  background-color: #ccc; /* 禁用状态样式 */
  cursor: not-allowed; /* 禁止光标 */
}

.loader {
  border: 3px solid rgba(255, 255, 255, 0.3); /* loader 边框颜色 */
  border-top: 3px solid white; /* loader 顶部颜色 */
  border-radius: 50%; /* 圆形 */
  width: 15px; /* loader 宽度 */
  height: 15px; /* loader 高度 */
  animation: spin 1s linear infinite; /* 动画效果 */
  position: absolute; /* 绝对定位 */
  left: 50%; /* 居中 */
  top: 50%; /* 居中 */
  transform: translate(-50%, -50%); /* 完全居中 */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>