import Home from "@/views/Home/index.vue"
const Info = () => import("@/views/payment/center/userInfo/index")
const AiVoice = () => import("@/views/aivoice/index")
const Usage = () => import("@/views/payment/center/useForm")
const Bill = () => import("@/views/payment/center/payForm")
const Agent = () => import("@/views/payment/center/agent")
const Plat = () => import("@/views/payment/center/plat")
const Pay = () => import("@/views/pay")
const RechargeDetail = () => import("@/views/recharge/rechargeDetail")
const RechargePay = () => import("@/views/recharge/pay")
const PaySuccess = () => import("@/views/recharge/paySuccess")
const PayError = () => import("@/views/recharge/payError")
const Center = () => import("@/views/payment/center")
const BindEmail = () => import("@/views/bind/bindEmail")
const BindPhone = () => import("@/views/bind/bindPhone")
export default [
  // 路由规则
  // {
  //     path: '/',
  //     name: '首页',
  //     component: () => import('@/views/Home.vue')
  // },
  // 6.28 当前版本暂不添加
  {
    path: "/course",
    name: "课程",
    component: () => import("@/views/Course/index.vue"),
  },
  {
    path: "/PowerPoint",
    name: "PowerPoint",
    component: () => import("@/views/PowerPoint/index.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/login.vue"),
  },
  {
    path: "/welcome",
    name: "welcome",
    component: () => import("@/views/welcome/welcome.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/register/register.vue"),
  },
  {
    path: "/reset",
    name: "reset",
    component: () => import("@/views/reset/reset.vue"),
  },
  {
    path: "/resend",
    name: "resend",
    component: () => import("@/views/reset/resend.vue"),
  },
  {
    path: "/mobile",
    name: "mobile",
    component: () => import("@/components/flash/mobile.vue"),
  },
  {
    path: "/pc",
    name: "pc",
    component: () => import("@/components/flash/pc.vue"),
  },
  {
    path: "/qimiaoHuman",
    name: "qimiaoHuman",
    component: () => import("@/components/flash/qimiaoHuman.vue"),
  },
  // {
  //   path: "/welcomeBack",
  //   name: "welcomeBack",
  //   component: () => import("@/views/login/welcomeBack.vue"),
  // },
  // {
  //   path: "/welcome",
  //   name: "welcome",
  //   component: () => import("@/views/login/welcome.vue"),
  // },
  // {
  //   path: "/register",
  //   name: "register",
  //   component: () => import("@/views/login/register.vue"),
  // },
  // {
  //   path: "/register-check",
  //   name: "register-check",
  //   component: () => import("@/views/login/register-check.vue"),
  // },
  {
    path: "/PowerPointTest",
    name: "PowerPointTest",
    component: () => import("@/views/PowerPoint/test.vue"),
  },
  {
    path: "/RechargeDetail",
    name: "RechargeDetail",
    component: RechargeDetail,
  },
  {
    path: "/QRcode",
    name: "QRcode",
    component: RechargePay,
  },
  {
    path: "/paySuccess",
    name: "paySuccess",
    component: PaySuccess,
  },
  {
    path: "/payError",
    name: "payError",
    component: PayError,
  },
  {
    path: "/center",
    name: "center",
    component: Center,
    children: [
      {
        path: "/usage",
        name: "usage",
        component: Usage,
      },
      {
        path: "/pay",
        name: "pay",
        component: Pay,
      },
      {
        path: "/info",
        name: "info",
        component: Info,
        children: [
          {
            path: 'aiVoice', // 子路由路径
            name: 'aiVoice',
            component: AiVoice,
          },
        ],
      },
      {
        path: "/bill",
        name: "bill",
        component: Bill,
      },
      {
        path: "/agent",
        name: "agent",
        component: Agent,
      },
      {
        path: "/plat",
        name: "plat",
        component: Plat,
      },
    ],
  },
  {
    path: "/bindEmail",
    name: "bindEmail",
    component: BindEmail,
  },
  {
    path: "/bindPhone",
    name: "bindPhone",
    component: BindPhone,
  },
]
