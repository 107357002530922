<!-- 奇妙人备注统一格式 -->
<template>
  <p class='qi-note'>
    {{ value }}
  </p>
</template>

<script>
// import { mapState } from 'vuex' // 从store中导入【全局】数据
// 定义Vue组件的业务逻辑
export default {
  // 为组件命名
  // name:'',
  // 加载子目录组件👇
  // components: {
  // } ,
  // 如果作为子组件向父组件传递
  props: {
    value: { // 备注文字
      type: String,
      required: true
    }
  },

  // 私有数据
  data() {
    return {}
  },
  // 创建生命周期函数,立即获取所有元素
  // 当页面刚加载的时候会执行的钩子函数
  created() { },
  // 处理函数
  methods: {},

  // 将【全局】数据,映射为当前组件的计算属性
  computed: {
    // ...mapState([''])
  },

  // ...其他业务逻辑
}
</script>

<style lang='less' scoped>
/**这个语法中支持less scoped只在当前组建生效，没有则全局生效 */
/**定义组件的形式 */
.qi-note {
  font-weight: 400 !important;
  font-size: 12px !important;
  color: var(--tip-color) !important;
}
</style>