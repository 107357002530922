// verifyToken.js
export function verifyToken() {
  // 查看链接自定义传参里，有没有带有用户token
  let url_userData = JSON.parse(new URLSearchParams(window.location.search).get("userData"))
  if (url_userData) {
    console.log('url_userData',url_userData)
    localStorage.setItem('userData', JSON.stringify(url_userData));
    // return url_userData // 返回userData值
  } 
  // 全局token检测以检验用户token是否存在
  if (localStorage.getItem("userData")) {
    const userData = localStorage.getItem("userData")
    return userData
  } else {
    window.location.href = "/" // 如果都没有,重登
  }
}
