<template>
  <div class="qi-data-table">
    <div class="table">
      <div class="thead">
        <div class="th" v-for="(column, index) in columns" :key="index" :class="column.type"
          :style="getColumnStyle(index)">
          {{ column.title }}
        </div>
      </div>
      <div class="tbody">
        <div class="tr" v-for="(row, rowIndex) in data" :key="rowIndex">
          <div class="td" v-for="(cell, cellIndex, index) in row" :key="cellIndex" :class="(cell.type || typeof cell)"
            :style="getColumnStyle(index)">
            {{ cell.data || cell }} </div>
            <!-- 数据解构分为两种情况
           1. 直接搬运数据，输出默认值：
              因为number类数据（如次数，金额等）要右对齐，
              所以数据默认String类左对齐，number类右对齐
           2. number类数据（尤其是金额）经过处理后，可能变为String，导致左对齐
              所以要在父组件额外添加类型，在子组件优先检索类型
              （如果有string类数据返回为type:number,则右对齐处理，反之亦然；
                如果没有type:number，则默认按照返回数据类型处理）
            -->
        </div>
      </div>
    </div>
    <div v-if="showPagination" class="pagination "
      :class="currentWidth ? 'flex-x-between' : 'flex-y-left pagination-mini'">
      <div class="total">
        <!-- <span>每页显示条数</span> -->
        <!-- <select class="mouseHead" v-model="itemsPerPage" @change="updatePagination">
          <option v-for="option in pageOptions" :key="option" :value="option">{{ option }}</option>
        </select> -->
        <!-- <span class="page">
          <span>第 {{ currentPage * itemsPerPage + 1 }} - {{ Math.min((currentPage + 1) * itemsPerPage, totalItems) }} 条
          </span>
          <span> 共计 {{ totalItems }} 条</span>
        </span> -->
      </div>

      <div class="btns flex-x-center-centerHor">
        <!-- <i class="iconfont icon-begin mouseHead " :class="String(currentPage == 0)" @click="goToFirstPage"></i> -->
        <!-- <i class="icon mouseHead el-icon-arrow-left " :class="String(currentPage == 0)" @click="goToPreviousPage"></i> -->
        <!-- <span class="pages">
          <span class="pageNum">{{ currentPage + 1 }}</span>
          {{ (totalItems / itemsPerPage + 1).toFixed(0) }}
        </span> -->
        <!-- <span v-for="(i, index) in pages" class="mouseHead pages " :class="currentPage == index ? 'active' : ''">{{ i
          }}</span>
        <i class="icon mouseHead el-icon-arrow-right " :class="String(currentPage >= totalPages - 1)"
          @click="goToNextPage"></i> -->
        <!-- <i class="iconfont icon-end mouseHead " :class="String(currentPage >= totalPages - 1)"
          @click="goToLastPage"></i> -->
        <div class="block">
          <el-pagination layout="prev, pager, next" :page-size="itemsPerPage" :pager-count="5" :total="total"
            @size-change="handleSizeChange" @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    columns: {
      type: Array,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    showPagination: {
      type: Boolean,
      default: true
    },
    pageOptions: {
      type: Array,
      default: true
    },
    total: {
      type: Number,
      default: true // 该属性是必需的
    },
    currentPagePay: {
      type: Number,
      default: true // 该属性是必需的
    },
  },
  data() {
    return {
      currentPage: 0,
      itemsPerPage: this.pageOptions[0],
      currentWidth: ""//监听元素宽度
    };
  },
  mounted() {
    this.checkWidth(); // 初始检查宽度
    window.addEventListener('resize', this.checkWidth); // 监听窗口大小变化
  },
  computed: {
    totalItems() {
      return this.total;
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    pages(size) {
      size = 6
      let endPage = (this.totalItems / this.itemsPerPage + 1).toFixed(0)
      const pageArray = []
      if (endPage <= size) {
        for (let i = 0; i < endPage; i++) {
          pageArray.push(i);
        }
        return pageArray;
      } else {
        for (let i = 0; i < size - 1; i++) {
          pageArray.push(i);
        }
        pageArray[size - 1] = '...'
        pageArray[size] = endPage
        return pageArray;
      }

    }
  },
  methods: {
    updatePagination() {
      this.$emit('updatePagination', this.itemsPerPage)
      this.currentPage = 0;
    },
    goToFirstPage() { // 去第一页
      if (this.currentPage == 0) {
        console.log('1', this.currentPage)
        return;//在第一页时禁用
      } else {
        console.log('2', this.currentPage)
        this.currentPage = 0;
        this.$emit('goToFirstPage', this.currentPage)
      }
    },
    goToPreviousPage() { // 上一页
      if (this.currentPage == 0) {
        return//在第一页时禁用
      } else {
        if (this.currentPage > 0) {
          this.currentPage--;
          this.$emit('goToPreviousPage', this.currentPage)
        }
      }
    },
    goToNextPage() { // 下一页
      if (this.currentPage >= this.totalPages - 1) {
        return;// 在最后一页时禁用
      } else {
        if (this.currentPage < this.totalPages - 1) {
          this.currentPage++;
          this.$emit('goToNextPage', this.currentPage)
        }

      }
    },
    goToLastPage() { // 最后一页
      if (this.currentPage >= this.totalPages - 1) {
        return;// 在最后一页时禁用
      } else {
        this.currentPage = this.totalPages - 1;
        this.$emit('goToLastPage', this.currentPage)
      }
    },
    getColumnStyle(index) {
      if (this.columns[index].width) {
        return { width: this.columns[index].width }; // 使用自定义宽度
      }
      if (index < 2) {
        return { width: '138px' }; // 前两列宽度固定为138px
      } 
      else {
        console.log(index)
        return { width: `calc((100% - (${this.columns[0].width} + ${this.columns[1].width})) / (${this.columns.length - 2}))` }; // 后面的列宽度平均分配
      }
    },
    btnSta(value) {
      const map = {
        0: 'unworking',
        '2': '系统奖励',
        '3': '注册赠送',
        '4': '课程赠送',
        '5': '运营充值'
      };
      return map[value] || 'working';
    },

    checkWidth() {
      const paginationElement = this.$refs.pagination;
      if (paginationElement) {
        const width = paginationElement.offsetWidth; // 获取元素的宽度
        this.currentWidth = width < 650; // 根据宽度设置值
      }
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.$emit('size-change', val)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.$emit('current-change', val)
    }
  },
  watch: {
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkWidth); // 清理事件监听
  },
}
</script>

<style scoped lang="less">
.string {
  text-align: left;
}

.number {
  text-align: right;
}

.qi-data-table {
  width: 100%;
  background-color: #fff;
  color: var(--icon-black);
}

.table {
  width: 100%;
  min-width: 380px;
  border-collapse: collapse;

  .thead {
    height: 56px;
    background-color: var(--left-bar-bgcolor);

    .th {
      height: 56px;
      line-height: 56px;
      padding: 0 16px;
      display: inline-block;
    }
  }


  .tbody {
    background-color: var(--left-bar-bgcolor);
    gap: 8px;
  }

  .tr {
    height: 52px;
    line-height: 52px;
    margin-bottom: 8px;
    background-color: #fff;
    display: inline-block;
    width: 100%;
    border-radius: 4px;

    .td {
      padding: 0 16px;
      display: inline-block;
    }
  }

  .tr:hover {
    // background-color: var(--left-bar-bgcolor);
  }
}

.pagination {
  padding: 30px 0;
  gap: 50px;
  font-size: 14px;
  min-width: 380px;
  background-color: var(--left-bar-bgcolor);

  .total {
    >span {
      margin-right: 8px;
    }

    select {
      width: 90px;
      height: 28px;
      border-radius: 4px;
      margin-right: 45px;
    }

    select:focus {
      border: 1px solid var(--theme-color);
    }
  }

  .btns {
    font-weight: 700 !important;
    gap: 42px;
    font-size: 13px;

    .pages:hover,
    .active {
      color: var(--theme-color);
    }

    .icon {
      font-size: 12px;
      font-weight: 700;
    }

    .true {
      opacity: .5;
      cursor: not-allowed;
    }
  }
}

.pagination-mini {
  gap: 8px;
}
</style>