// global.js

export function deviceWidth() {
  // 查询设备宽度
  return window.innerWidth;
}

export function fileIcon(filename) {    // 2024.9.29 根据文件格式显示图标
    const extension = filename.split('.').pop().toLowerCase(); // 获取文件后缀
    const iconMap = {
      'xlsx': 'icon-excel',
      'xls': 'icon-excel', // Excel 图标 /
      'docx': 'icon-doc',
      'doc': 'icon-doc', // Word 图标 /
      'pptx': 'icon-ppt', // PowerPoint 图标 /
      'ppt': 'icon-ppt',
      'pdf': 'icon-pdf', // PDF 图标 /
      'txt': 'icon-icon_txt' // txt 图标 /
    };
    return iconMap[extension] || 'icon-qitageshi'; // 其他图标
}