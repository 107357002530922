const GlobalPlugin = {
  install(Vue) {
    Vue.prototype.$verifyToken = function () {
      // 全局token检测以检验用户token是否存在
      if (localStorage.getItem("userData")) {
        const userData = localStorage.getItem("userData")
        return userData
      }
      // 查看链接自定义传参里，有没有带有用户token
      let url_userData = new URLSearchParams(window.location.search).get("userData")
      if (url_userData) { 
        return url_userData // 返回userData值
      } else {
        return window.location.href = "/"; // 如果都没有,重登
      }
    }
  },
}

export default GlobalPlugin
